<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Energy</h1>
      <div>
        <div>
          <p>A key consumer of energy in most building is its HVAC system.</p>
          <p>
            <strong>HVAC</strong> stands for heating, ventilation, and air
            conditioning. This system provides heating and cooling to buildings
            and the means for environmental comfort.
          </p>
          <p>
            An <strong>HVAC system</strong> is responsible for heating and
            cooling your home or business and includes products like furnaces,
            air conditioners, heat pumps as well as ductwork, thermostats and
            other home comfort controls.
          </p>
          <p>
            Your HVAC system is probably the largest energy consumer in your
            home, and so it is worth tracking that energy consumption. By doing
            so, you will be able to spot inefficiencies should they occur, as
            well as automatically compute your greenhouse gas emissions.
          </p>
          <p>
            Energy requires fuel, and that fuel for your HVAC system comes in
            several forms, such as Electricity (that powers heat pumps, air
            conditioning units, baseboard heaters), Oil or Propane (fuel for a
            furnace or boiler to make heat), Wood (for a wood stove), or some
            Other form of fuel.
          </p>
          <p>
            In addition to energy used for heating and cooling, every home
            consumes Electric energy for lighting, refrigeration, other
            appliances, etc.
          </p>

          <p>
            The Energy Peeps feature will enable you to easily specify what fuel
            or fuels your HVAC system uses, as well as track your energy
            consumption from it.
          </p>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'About Energy'
    }
  }
}
</script>
